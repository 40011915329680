<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Hizmetler</h2>
            <p>Hizmetlerimiz</p>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>BT Danışmanlığı</h3>
                    <p>BT çözümlerinizde güvenilir bir yol arkadaşıyız! İş süreçlerinizi optimize etmek ve teknolojiden en iyi şekilde yararlanmanız için yanınızdayız.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                <!--<a routerLink="/services-details" class="read-more">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>-->
                <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-mobile'></i>
                    </div>
                    <h3>Mobil Uygulamalar</h3>
                    <p>Mobil dünyada sizi bir adım öne taşıyoruz! Kullanıcı dostu ve yenilikçi mobil uygulamalarla işinizi her an, her yerde erişilebilir kılıyoruz.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>BT Çözümleri</h3>
                    <p>Teknolojiyi iş gücüne dönüştürüyoruz! İhtiyacınıza özel BT çözümleriyle verimliliğinizi artırıyor ve geleceğe hazır hale gelmenizi sağlıyoruz.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-laptop'></i>
                    </div>
                    <h3>Web Geliştirme</h3>
                    <p>şinizi dijital dünyada parlatıyoruz! Modern ve etkileyici web çözümleriyle markanızın çevrimiçi gücünü artırıyoruz.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cart'></i>
                    </div>
                    <h3>eTicaret</h3>
                    <p>Satışlarınızı dijitale taşıyoruz! Güçlü ve kullanıcı dostu e-ticaret çözümleriyle işinizi büyütmenize destek oluyoruz.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Proje Yönetimi</h3>
                    <p>Projelerinizi başarıyla yönetiyoruz! Etkin planlama ile hedeflerinize zamanında ve verimli şekilde ulaşmanızı sağlıyoruz.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-data'></i>
                    </div>
                    <h3>Web Barındırma</h3>
                    <p>Web siteniz için güvenli, hızlı ve kesintisiz barındırma çözümleri sunarak dijital varlığınızı güçlendiriyor ve başarınızı destekliyoruz!</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Teknik Destek</h3>
                    <p>eknolojik sorunlarınıza hızlı, etkili ve kalıcı çözümler sunarak işlerinizi kesintisiz ve verimli şekilde sürdürmenizi sağlıyoruz!</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>SEO, Dijital Pazarlama</h3>
                    <p>Markanızı dijitalde zirveye taşıyoruz! SEO, yazılım ve dijital pazarlama ile görünürlüğünüzü artırıp hedef kitlenize ulaşıyoruz!</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more" style="pointer-events: none; cursor: default;">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
