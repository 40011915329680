<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/ana-sayfa" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ANA SAYFA</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/biz-kimiz" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HAKKIMIZDA</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/hizmetlerimiz" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HİZMETLERİMİZ</a></li>

                    <!--
                    <li class="nav-item"><a href="#" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="#" class="nav-link">Service <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services One</a></li>
                                    
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>
    
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                </ul>
                            </li>
                    

                            <li class="nav-item"><a href="#" class="nav-link">Features <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/features-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features Style One</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/features-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features Style Two</a></li>
                                </ul>
                            </li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                        </ul>
                    </li>

            -->

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/fiyat-planlari" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FİYATLANDIRMA</a></li>

                    <!--
                    <li class="nav-item">
                        <a href="#" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                        </ul>
                    </li>
                    -->

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/bize-ulasin" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">BİZE ULAŞIN</a></li>
                </ul>
                <div class="others-options">
                    <a routerLink="/bize-ulasin" class="default-btn"><i class="bx bxs-hot"></i>Başlayın<span></span></a>
                    <a routerLink="/log-in" class="optional-btn"><i class="bx bx-log-in"></i>Giriş<span></span></a>

                </div>
            </div>
        </nav>
    </div>
</div>