import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { FeaturesOneComponent } from './components/pages/features-one/features-one.component';
import { FeaturesTwoComponent } from './components/pages/features-two/features-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { Title } from '@angular/platform-browser';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    "data": {
      "meta": {
        "title": "Ana Sayfa - Dijital Ajans, Reklam ve SEO Çözümleri",
        "description": "Dijital ajans hizmetlerimizle markanızı büyütmek için yazılım, reklam ajansı ve SEO çözümleri sunuyoruz. Sosyal medya yönetimi, yaratıcı tasarımlar ve dijital pazarlama ile işinizi ileri taşıyın.",
        "robots": "index, follow",
        "keywords": "dijital ajans, reklam ajansı, yazılım, SEO, sosyal medya yönetimi, dijital pazarlama, içerik stratejisi, yaratıcı tasarım",
        "openGraph": {
          "title": "Ana Sayfa - Dijital Ajans ve SEO Çözümleri",
          "description": "Web sitenizin sıralamasını yükseltmek ve sosyal medya görünürlüğünüzü artırmak için SEO ve dijital pazarlama çözümleri.",
          "image": "https://sizisoylealalim.com.tr/assets/img/homepage-banner.webp",
          "url": "https://www.sizisoylealalim.com.tr",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Ana Sayfa - Dijital Ajans ve SEO Çözümleri",
          "description": "Markanız için en iyi SEO ve dijital reklam stratejileri. Sosyal medya yönetimi, içerik planlama ve tasarım çözümleri.",
          "image": "https://sizisoylealalim.com.tr/assets/img/twitter-card-home.webp"
        },
        "linkedin": {
          "title": "Ana Sayfa - Dijital Ajans, Yazılım ve SEO Hizmetleri",
          "description": "Dijital ajans olarak SEO, yazılım ve reklam çözümleri sunuyoruz. Hedeflerinize ulaşmak için kapsamlı stratejiler geliştiriyoruz.",
          "image": "https://example.com/assets/images/linkedin-home.jpg",
          "url": "https://www.sizisoylealalim.com.tr"
        }
      }
    }
  },
  {
    path: 'ana-sayfa',
    component: HomeComponent,
    "data": {
      "meta": {
        "title": "Ana Sayfa - Dijital Ajans, Reklam ve SEO Çözümleri",
        "description": "Dijital ajans hizmetlerimizle markanızı büyütmek için yazılım, reklam ajansı ve SEO çözümleri sunuyoruz. Sosyal medya yönetimi, yaratıcı tasarımlar ve dijital pazarlama ile işinizi ileri taşıyın.",
        "robots": "index, follow",
        "keywords": "dijital ajans, reklam ajansı, yazılım, SEO, sosyal medya yönetimi, dijital pazarlama, içerik stratejisi, yaratıcı tasarım",
        "openGraph": {
          "title": "Ana Sayfa - Dijital Ajans ve SEO Çözümleri",
          "description": "Web sitenizin sıralamasını yükseltmek ve sosyal medya görünürlüğünüzü artırmak için SEO ve dijital pazarlama çözümleri.",
          "image": "https://sizisoylealalim.com.tr/assets/img/homepage-banner.webp",
          "url": "https://www.sizisoylealalim.com.tr",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Ana Sayfa - Dijital Ajans ve SEO Çözümleri",
          "description": "Markanız için en iyi SEO ve dijital reklam stratejileri. Sosyal medya yönetimi, içerik planlama ve tasarım çözümleri.",
          "image": "https://sizisoylealalim.com.tr/assets/img/twitter-card-home.webp"
        },
        "linkedin": {
          "title": "Ana Sayfa - Dijital Ajans, Yazılım ve SEO Hizmetleri",
          "description": "Dijital ajans olarak SEO, yazılım ve reklam çözümleri sunuyoruz. Hedeflerinize ulaşmak için kapsamlı stratejiler geliştiriyoruz.",
          "image": "https://example.com/assets/images/linkedin-home.jpg",
          "url": "https://www.sizisoylealalim.com.tr"
        }
      }
    }
  },
  {
    path: 'biz-kimiz',
    component: AboutComponent,
    "data": {
      "meta": {
        "title": "Hakkımızda - Sizi Şöyle Alalım - Dijital Pazarlama, SEO ve Sosyal Medya Yönetimi Çözümleri",
        "description": "Sizi Şöyle Alalım, markaların dijital dünyada büyümesi için SEO, dijital pazarlama ve sosyal medya yönetimi alanlarında yenilikçi ve stratejik çözümler sunar. Uzman ekibimizle hedeflerinize ulaşmanıza yardımcı oluyoruz.",
        "robots": "index, follow",
        "keywords": "dijital pazarlama, SEO, sosyal medya yönetimi, strateji, büyüme",
        "openGraph": {
          "title": "Hakkımızda - Sizi Şöyle Alalım - Dijital Pazarlama Çözümleri",
          "description": "Sizi Şöyle Alalım, markaların dijital dünyada büyümesi için yenilikçi çözümler sunar.",
          "image": "https://cuneyterdem.com/assets/img/about-img.webp",
          "url": "https://example.com/about",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Hakkımızda - Sizi Şöyle Alalım - Dijital Pazarlama Çözümleri",
          "description": "Markaların dijital dünyada büyümesine yardımcı olacak yenilikçi çözümler sunuyoruz.",
          "image": "https://example.com/images/about-us.jpg"
        },
        "team": [
          {
            "name": "Emre Üstünkurucu",
            "role": "CEO & Founder",
            "image": "https://sizisoylealalim.com.tr/img/team/emre-ustunkurucu.webp",
            "linkedin": "https://linkedin.com/in/emre-ustunkurucu",
            "twitter": "https://twitter.com/emreustunkurucu",
            "bio": "Emre Üstünkurucu, dijital pazarlama dünyasında yenilikçi çözümler üreten deneyimli bir liderdir. Stratejik vizyonuyla markaları dijital dünyada başarıya taşıyor."
          },
          {
            "name": "Feray Sarısaçlı",
            "role": "UX/UI Designer",
            "image": "https://sizisoylealalim.com.tr/img/team/feray-sarisacli.webp",
            "linkedin": "https://linkedin.com/in/feray-sarisacli",
            "twitter": "https://twitter.com/feraysarisacli",
            "bio": "Feray Sarısaçlı, kullanıcı odaklı tasarımlarıyla dijital deneyimleri mükemmelleştiriyor."
          },
          {
            "name": "Arzu Terzioğlu",
            "role": "Web Developer",
            "image": "https://sizisoylealalim.com.tr/img/team/arzu-terzioglu.webp",
            "linkedin": "https://linkedin.com/in/arzu-terzioglu",
            "twitter": "https://twitter.com/arzu_terzioglu",
            "bio": "Arzu Terzioğlu, yenilikçi web geliştirme çözümleriyle projelere hayat veriyor."
          },
          {
            "name": "Kenan Sarısakal",
            "role": "SEO Expert",
            "image": "https://sizisoylealalim.com.tr/img/team/kenan-sarisakal.webp",
            "linkedin": "https://linkedin.com/in/kenan-sarisakal",
            "twitter": "https://twitter.com/kenansarisakal",
            "bio": "Kenan Sarısakal, veri odaklı SEO stratejileriyle markaların dijital görünürlüğünü artırıyor."
          }
        ]
      }
    }
  },
  {
    path: 'fiyat-planlari',
    component: PricingComponent,
    "data": {
      "meta": {
        "title": "Şeffaf Fiyatlandırma - Sosyal Medya ve SEO Çözümleri",
        "description": "Sizi Şöyle Alalım ile uygun fiyatlarla sosyal medya ve SEO hizmetleri! Başlangıç ve Orta paketlerle işletmenizin dijital dünyada büyümesine yardımcı oluyoruz.",
        "robots": "index, follow",
        "keywords": "şeffaf fiyatlandırma, sosyal medya yönetimi, SEO hizmetleri, dijital pazarlama paketleri, uygun fiyat",
        "openGraph": {
          "title": "Şeffaf Fiyatlandırma - Sosyal Medya ve SEO Çözümleri",
          "description": "Uygun fiyatlı dijital pazarlama paketleri ile markanızı büyütün. Sosyal medya yönetimi ve SEO hizmetleri sunuyoruz.",
          "image": "https://sizisoylealalim.com.tr/assets/img/pricing.webp",
          "url": "https://sizisoylealalim.com/fiyat-planlari",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Şeffaf Fiyatlandırma - Sosyal Medya ve SEO Çözümleri",
          "description": "Markanız için en uygun dijital pazarlama fiyatlandırma paketlerini inceleyin.",
          "image": "https://sizisoylealalim.com.tr/img/fiyat-planlari.jpg"
        }
      },
      "pricing": [
        {
          "name": "Başlangıç",
          "price": "$10 / aylık",
          "features": [
            "1 Sosyal Medya Platform Yönetimi",
            "10 SEO Anahtar Kelime Takibi",
            "2 Instagram Post Paylaşımı",
            "Eposta Entegrasyonu",
            "Haftalık Raporlama",
            "Ziyaretçi Bilgisi"
          ],
          "cta": "Hemen Dene"
        },
        {
          "name": "Orta",
          "price": "$29 / aylık",
          "features": [
            "3 Sosyal Medya Platform Yönetimi",
            "50 SEO Anahtar Kelime Takibi",
            "5 Instagram Post Paylaşımı",
            "Eposta Entegrasyonu",
            "2 Reels Video Paylaşımı",
            "Günlük Raporlama",
            "5 Backlink"
          ],
          "cta": "Hemen Dene"
        }
      ]
    }
  },
  {
    path: 'hizmetlerimiz',
    component: ServicesTwoComponent,
    "data": {
      "meta": {
        "title": "Hizmetlerimiz - BT Danışmanlığı, Mobil Uygulamalar, SEO ve Dijital Çözümler",
        "description": "BT danışmanlığı, mobil uygulama geliştirme, web çözümleri, e-ticaret, SEO ve teknik destek gibi hizmetlerimizle işinizi dijital dünyada bir adım öne taşıyoruz.",
        "robots": "index, follow",
        "keywords": "BT danışmanlığı, mobil uygulamalar, web geliştirme, e-ticaret çözümleri, teknik destek, SEO, dijital pazarlama, proje yönetimi",
        "openGraph": {
          "title": "Hizmetlerimiz - Dijital Çözümler ve Teknoloji Hizmetleri",
          "description": "Uzman ekibimizle dijital çözümler sunuyoruz. BT danışmanlığı, SEO, mobil uygulamalar, e-ticaret ve daha fazlası için bize ulaşın.",
          "image": "https://sizisoylealalim.com.tr/assets/img/services-overview.webp",
          "url": "https://sizisoylealalim.com.tr/hizmetler",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Hizmetlerimiz - Dijital Çözümler ve Teknoloji Hizmetleri",
          "description": "BT danışmanlığı, mobil uygulamalar, web geliştirme ve SEO hizmetleriyle işinizi büyütmenize yardımcı oluyoruz.",
          "image": "https://sizisoylealalim.com.tr/images/services-banner.webp"
        }
      },
      "services": [
        {
          "title": "BT Danışmanlığı",
          "description": "BT çözümlerinizde güvenilir bir yol arkadaşıyız! İş süreçlerinizi optimize etmek ve teknolojiden maksimum verim almanızı sağlamak için uzman ekibimizle yanınızdayız.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "Mobil Uygulamalar",
          "description": "Mobil dünyada sizi bir adım öne taşıyoruz! Kullanıcı dostu ve yenilikçi mobil uygulamalarla işinizi her an, her yerde erişilebilir kılıyoruz.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "BT Çözümleri",
          "description": "Teknolojiyi iş gücüne dönüştürüyoruz! İhtiyacınıza özel BT çözümleriyle verimliliğinizi artırıyor ve geleceğe hazır hale gelmenizi sağlıyoruz.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "Web Geliştirme",
          "description": "İşinizi dijital dünyada parlatıyoruz! Modern ve etkileyici web çözümleriyle markanızın çevrimiçi gücünü artırıyoruz.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "eTicaret",
          "description": "Satışlarınızı dijitale taşıyoruz! Güçlü ve kullanıcı dostu e-ticaret çözümleriyle işinizi büyütmenize destek oluyoruz.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "Proje Yönetimi",
          "description": "Projelerinizi başarıyla yönetiyoruz! Etkin planlama ile hedeflerinize zamanında ve verimli şekilde ulaşmanızı sağlıyoruz.",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "Web Barındırma",
          "description": "Web siteniz için güvenli, hızlı ve kesintisiz barındırma çözümleri sunarak dijital varlığınızı güçlendiriyoruz!",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "Teknik Destek",
          "description": "Teknolojik sorunlarınıza hızlı, etkili ve kalıcı çözümler sunarak işlerinizi kesintisiz ve verimli şekilde sürdürmenizi sağlıyoruz!",
          "link": "https://sizisoylealalim.com.tr/hizmetlerimiz"
        },
        {
          "title": "SEO ve Dijital Pazarlama",
          "description": "Markanızı dijitalde zirveye taşıyoruz! SEO ve dijital pazarlama ile görünürlüğünüzü artırıp hedef kitlenize ulaşıyoruz!",
          "link": "https://example.com/seo-dijital-pazarlama"
        }
      ]
    }

  },
  {
    path: 'bize-ulasin',
    component: ContactComponent,
    "data": {
      "meta": {
        "title": "Bize Ulaşın - Sizi Şöyle Alalım | Dijital Pazarlama ve SEO Çözümleri",
        "description": "Bize ulaşarak dijital pazarlama, SEO ve sosyal medya yönetimi hizmetlerimiz hakkında bilgi alabilirsiniz. İşletmenizi büyütmek için yanınızdayız.",
        "robots": "index, follow",
        "keywords": "bize ulaşın, iletişim, dijital pazarlama, SEO, sosyal medya yönetimi, Sizi Şöyle Alalım",
        "openGraph": {
          "title": "Bize Ulaşın - Sizi Şöyle Alalım | Dijital Pazarlama ve SEO Çözümleri",
          "description": "Dijital dünyada başarıya ulaşmak için bizimle iletişime geçin. Sosyal medya, SEO ve daha fazlası için buradayız.",
          "image": "https://sizisoylealalim/assets/img/contact-us.webp",
          "url": "https://sizisoylealalim.com.tr/bize-ulasin",
          "type": "website"
        },
        "twitter": {
          "card": "summary_large_image",
          "title": "Bize Ulaşın - Sizi Şöyle Alalım | Dijital Çözümler",
          "description": "Markanızı büyütmek için dijital çözümler sunuyoruz. İletişime geçin!",
          "image": "https://sizisoylealalim/images/contact-us.jpg"
        }
      },
      "content": {
        "heading": "Bize Ulaşın",
        "subheading": "Herhangi bir sorunuz için bize mesaj bırakın.",
        "contactForm": {
          "fields": [
            { "name": "Adınız", "type": "text", "required": true },
            { "name": "E-postanız", "type": "email", "required": true },
            { "name": "Telefonunuz", "type": "tel", "required": false },
            { "name": "Konunuz", "type": "text", "required": true },
            { "name": "Mesajınız", "type": "textarea", "required": true }
          ],
          "button": { "text": "Mesaj Gönder" }
        },
        "alternativeContact": {
          "title": "Telefon Numarası veya E-posta Adresi ile Bize Ulaşın",
          "contactInfo": [
            { "type": "phone", "value": "+90 (530) 466 7251" },
            { "type": "email", "value": "bilgi@sizisoylealalim.com.tr" }
          ]
        },
        "statistics": [
          { "label": "Tamamlanmış Projeler", "value": "900+" },
          { "label": "Mutlu Müşteriler", "value": "850+" },
          { "label": "Devam Eden Projeler", "value": "15+" },
          { "label": "Kazanan Ödüller", "value": "3+" }
        ]
      }
    }
  },

  { path: 'services-1', component: ServicesOneComponent },
  { path: 'services-details', component: ServicesDetailsComponent },
  { path: 'features-1', component: FeaturesOneComponent },
  { path: 'features-2', component: FeaturesTwoComponent },
  { path: 'team', component: TeamComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'log-in', component: LogInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'blog-1', component: BlogGridComponent },
  { path: 'blog-2', component: BlogRightSidebarComponent },
  { path: 'blog-details', component: BlogDetailsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
