<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.webp" alt="login-image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <h3>Tekrar hoşgeldiniz!</h3>
                            <form>
                                <div class="form-group mb-3">
                                    <input type="email" placeholder="E-posta adresiniz" class="form-control">
                                </div>
                                <div class="form-group mb-3">
                                    <input type="password" placeholder="Şifreniz" class="form-control">
                                </div>
                                <button type="submit" class="default-btn"><i class="bx bxs-hot"></i>Giriş<span></span></button>
                                
                                <div class="connect-with-social">
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Facebook ile bağlanın</button>
                                    <button type="submit" class="google"><i class='bx bxl-google'></i> Google ile bağlantı kurun</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>