<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img src="assets/img/saas-shape/arrow.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <!-- Main image -->
                            <img src="assets/img/saas-shape/main-image.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>İş stratejilerinizi tek bir noktadan yönetin.</h1>
                            <p>Web sitenizin arama motorlarında daha üst sıralarda yer alması ve sosyal medyada daha geniş bir kitleye erişim sağlaması için özel stratejiler geliştiriyoruz. Profesyonel yaklaşımımız, veriye dayalı kararlarla desteklenen etkili kampanyalar ve sürdürülebilir büyüme sağlar. İşiniz için en iyisini hedefliyoruz!</p>
                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/bize-ulasin" class="default-btn"><i class="bx bxs-hot"></i> Hemen Ulaşın <span></span></a>
                                    <a href="https://www.youtube.com/watch?v=TqMUV8lSmGY" class="video-btn popup-youtube"> <i class='bx bxs-right-arrow'></i> Videomuzu İzle </a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-rotate rotateme">
        <img src="assets/img/saas-shape/shape-rotate.png" alt="img">
    </div>
    <div id="particles-js"></div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>İhtiyacınız Olan Her Şey</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <h3>Hızlı ve Optimize Edilmiş</h3>
                    <p>SEO stratejilerimizle web sitenizi arama motorlarına tam uyumlu hale getiriyoruz. Daha hızlı yükleme süreleri ve optimize edilmiş içeriklerle rakiplerinizin önüne geçin.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <h3>Tam Güvenlik</h3>
                    <p>Dijital varlıklarınızın güvenliği bizim için öncelikli. Güvenlik analizlerimiz ve danışmanlık hizmetlerimizle markanızı çevrimiçi tehditlere karşı koruyun.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <h3>Zaman Tasarrufu</h3>
                    <p>Dijital pazarlama kampanyalarınızı hızlı ve etkili bir şekilde yönetiyoruz. Zamanınızı işinize odaklanarak geçirmeniz için biz burada tüm süreçleri sizin yerinize yönetiyoruz.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <h3>Kolayca Yönetin</h3>
                    <p>Sosyal medya hesaplarınızı profesyonel bir şekilde yönetmek artık çok kolay. Markanızın sesi olmak için etkili içerikler ve düzenli paylaşımlar sağlıyoruz.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-lock-open bg-00d280'></i>
                    <h3>Hızlı Erişim</h3>
                    <p>Dijital dünyada hızlı sonuç almak için yanınızdayız. Reklam kampanyaları, sosyal medya stratejileri ve SEO çözümlerimizle hedeflerinize hızla ulaşın.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-droplet-half bg-ff612f'></i>
                    <h3>Yaratıcı Tasarım Çözümleri</h3>
                    <p>Yaratıcı tasarımlar ve logo çalışmalarımızla markanızı daha güçlü ve akılda kalıcı hale getiriyoruz. Görselleriniz için en etkili çözümler bizde.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/home-saas/social-media.webp" style="border-radius: 15px;" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="features-inner-content">
                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".1s">
                            <i class='bx bx-share-alt bg-13c4a1 blt-radius-0'></i>
                            <h3>Sosyal Medya Yönetimi</h3>
                            <p>Markanızın sosyal medya platformlarındaki varlığını profesyonelce yönetiyoruz. İçerik planlaması, etkileşim artırma stratejileri ve sosyal ağ danışmanlığıyla hedef kitlenize en etkili şekilde ulaşmanıza yardımcı oluyoruz.</p>
                        </div>
                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".2s">
                            <i class='bx bx-user-voice bg-6610f2 blt-radius-0'></i>
                            <h3>Günlük Ücretsiz Danışmanlık</h3>
                            <p>Günlük ücretsiz dijital pazarlama ve sosyal medya danışmanlığı ile markanızı bir adım öne taşıyoruz. Profesyonel tavsiyelerle işinizi büyütmenize yardımcı oluyoruz.</p>
                        </div>
                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                            <i class='bx bx-stats bg-ff612f blt-radius-0'></i>
                            <h3>Ücretsiz SEO Analizi</h3>
                            <p>Web sitenizin arama motorları için optimize edilmesini sağlıyoruz. Ücretsiz analizlerle SEO performansınızı en üst düzeye çıkararak daha fazla görünürlük elde edin.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image">
    </div>
</section>

<section class="ptb-100 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2 class="mb-2">Daha Fazlasını Keşfedin</h2>
        </div>
    </div>
    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/landing-page.webp" style="border-radius: 15px;" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">01</span>
                        <h3>Landing Page</h3>
                        <p>Hedef kitlenizi karşılayan ve dönüşümleri artıran etkili bir açılış sayfası tasarımı. Profesyonel çözümlerimizle markanızı daha güçlü hale getiriyor, hedeflerinize ulaşmanız için gerekli tüm araçları sağlıyoruz. Basit ve etkili bir başlangıç için yanınızdayız. </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Benzersiz Tasarım</li>
                            <li><i class='bx bx-badge-check'></i>Kolay Kullanıcı Deneyimi</li>
                            <li><i class='bx bx-badge-check'></i>Hızlı ve Güvenli İşlem</li>
                            <li><i class='bx bx-badge-check'></i>Esnek ve Özelleştirilebilir Yapı</li>
                        </ul>
                        <a routerLink="/biz-kimiz" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Devam <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">02</span>
                        <h3>Sosyal Medya Yönetimi</h3>
                        <p>Sosyal medya platformlarında markanızı öne çıkarmak için profesyonel çözümler sunuyoruz. Etkili içerik yönetimi, stratejik planlama ve yenilikçi yaklaşımlarla hedef kitlenize kolayca ulaşabilirsiniz. Bizimle sosyal medyada fark yaratın!</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Modern Tasarım</li>
                            <li><i class='bx bx-badge-check'></i>Etkili İçerik Planlama</li>
                            <li><i class='bx bx-badge-check'></i>Etkileşim Artırıcı Kampanyalar</li>
                            <li><i class='bx bx-badge-check'></i>Anlık Yorum ve Mesaj Yönetimi</li>
                            <li><i class='bx bx-badge-check'></i>Detaylı Analiz ve Raporlama</li>
                        </ul>
                        <a routerLink="/biz-kimiz" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Devam <span></span></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/home-saas/social-media-consultant.webp" style="border-radius: 15px;" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/seo-social-media.webp" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">03</span>
                        <h3>Kapsamlı SEO Çözümleri</h3>
                        <p>SEO uyumlu sosyal medya ve web içerikleri ile maksimum etkileşim. Sosyal medya platformlarında görünürlüğünüzü artırmak ve web sitenizin arama motorlarındaki sıralamasını yükseltmek için eksiksiz SEO stratejileri sunuyoruz. Markanızı öne çıkaracak çözümlerle hedef kitlenize en etkili şekilde ulaşabilirsiniz.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Optimize Edilmiş İçerik</li>
                            <li><i class='bx bx-badge-check'></i>Platforma Özgü Stratejiler</li>
                            <li><i class='bx bx-badge-check'></i>Hızlı Yükleme ve Performans</li>
                            <li><i class='bx bx-badge-check'></i>Etkileşim Odaklı SEO</li>
                            <li><i class='bx bx-badge-check'></i>Detaylı SEO Analizi</li>
                        </ul>
                        <a routerLink="/biz-kimiz" class="default-btn black-btn"><i class='bx bxs-arrow-to-right'></i>Devam <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Sizlere her zaman en iyi desteği sunmak için var gücümüzle çalışıyoruz.</h2>
            <p>Her zaman yanınızdayız! İhtiyaçlarınıza en uygun çözümleri sunmak ve beklentilerinizi aşmak için durmaksızın çalışıyoruz.</p>
            <a routerLink="/bize-ulasin" class="default-btn"><i class="bx bxs-hot"></i> Bize Ulaşın<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>