<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Şeffaf Fiyatlandırma</h2>
            <p>Sınırsız hesap fiyatlandırması</p>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Aylık</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yıllık</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Başlangıç</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>10 <sub>/ aylık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 1 Sosyal Medya Platform Yönetimi </li>

                                    <li><i class="bx bxs-badge-check"></i> 10 SEO Anahtar Kelime Takibi</li>

                                    <li><i class="bx bxs-badge-check"></i> 2 Instagram Post Paylaşımı</li>

                                    <li><i class="bx bxs-badge-check"></i> Eposta Entegrasyonu </li>

                                    <li><i class="bx bxs-badge-check"></i> Haftalık Raporlama</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Bilgisi</li>

                                    <li><i class="bx bxs-badge-check"></i> - </li>

                                    <li><i class="bx bxs-badge-check"></i> - </li>

                                    <li><i class="bx bxs-badge-check"></i> - </li>

                                    <li><i class="bx bxs-badge-check"></i> - </li>

                                    <li><i class="bx bxs-badge-check"></i> - </li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Orta</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>29 <sub>/ aylık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 3 Sosyal Medya Platform Yönetimi <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 50 SEO Anahtar Kelime Takibi</li>

                                    <li><i class="bx bxs-badge-check"></i> 5 Instagram Post Paylaşımı</li>

                                    <li><i class="bx bxs-badge-check"></i> Eposta Entegrasyonu <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 2 Reels Video Paylaşımı</li>

                                    <li><i class="bx bxs-badge-check"></i> Günlük Raporlama</li>

                                    <li><i class="bx bxs-badge-check"></i> 5 Backlink</li>

                                    <li><i class="bx bxs-badge-check"></i> - <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> -</li>

                                    <li><i class="bx bxs-badge-check"></i> - <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> -</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Profesyonel</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ aylık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 5 Sosyal Medya Platform Yönetimi <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 SEO Anahtar Kelime Takibi</li>

                                    <li><i class="bx bxs-badge-check"></i> 10 Instagram Post Paylaşımı</li>

                                    <li><i class="bx bxs-badge-check"></i> Eposta Entegrasyonu  <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 5 Reels Video Paylaşımı</li>

                                    <li><i class="bx bxs-badge-check"></i> 20 Backlink</li>

                                    <li><i class="bx bxs-badge-check"></i> 7/24 Destek</li>

                                    <li><i class="bx bxs-badge-check"></i> Aylık Özel Strateji Oturumu <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> -</li>

                                    <li><i class="bx bxs-badge-check"></i> - <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> -</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Başlangıç</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>100 <sub>/ yıllık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 3'e kadar sohbet operatörü <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Tetikleyicisi</li>

                                    <li><i class="bx bxs-badge-check"></i> 7/24 Canlı Sohbet</li>

                                    <li><i class="bx bxs-badge-check"></i> E-posta Entegrasyonu <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Entegrasyonu</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Bilgileri</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobil + Masaüstü Uygulamaları</li>

                                    <li><i class="bx bxs-badge-check"></i> Hızlı Yanıtlar <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Sürükle ve Bırak Widget'ları</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Notları <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analitik</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Orta</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>290 <sub>/ yıllık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 4'e kadar sohbet operatörü <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Tetikleyicisi</li>

                                    <li><i class="bx bxs-badge-check"></i> 7/24 Canlı Sohbet</li>

                                    <li><i class="bx bxs-badge-check"></i> E-posta Entegrasyonu <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Entegrasyonu</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Bilgileri</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobil + Masaüstü Uygulamaları</li>

                                    <li><i class="bx bxs-badge-check"></i> Hızlı Yanıtlar <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Sürükle ve Bırak Widget'ları</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Notları <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analitik</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Profesyonel</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>790 <sub>/ yıllık</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> 7'ye kadar sohbet operatörü <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Tetikleyicisi</li>

                                    <li><i class="bx bxs-badge-check"></i> 7/24 Canlı Sohbet</li>

                                    <li><i class="bx bxs-badge-check"></i> E-posta Entegrasyonu <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Entegrasyonu</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Bilgileri</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobil + Masaüstü Uygulamaları</li>

                                    <li><i class="bx bxs-badge-check"></i> Hızlı Yanıtlar <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Sürükle ve Bırak Widget'ları</li>

                                    <li><i class="bx bxs-badge-check"></i> Ziyaretçi Notları <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analitik</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Hemen Dene<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
