<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Bize Ulaşın</h2>
            <p>Herhangi bir sorunuz için bize mesaj bırakın</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Bizi seçmeniz için diğer bazı harika nedenler</h3>
                        <p>Bizi seçmeniz için diğer bazı harika nedenler arasında, SEO'dan sosyal medya yönetimine, tasarımdan marka stratejisine kadar sunduğumuz kapsamlı ve entegre hizmetler bulunuyor.</p>
                        <p>Uzman ekibimiz, her adımda işletmenizin ihtiyaçlarına özel çözümler sunarak, markanızı güçlendirmeye ve dijital dünyada öne çıkarmaya odaklanır. Şeffaf, güvenilir ve sonuç odaklı yaklaşımımızla başarıya giden yolda yanınızdayız.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> SEO'da Uzmanlık, Sosyal Medyada Etkinlik</li>
                            <li><i class='bx bx-badge-check'></i> Özgün ve Etkileyici Tasarımlar</li>
                            <li><i class='bx bx-badge-check'></i> Marka Stratejisi ve Kimlik Oluşturma</li>
                            <li><i class='bx bx-badge-check'></i> Tam Entegre Çözümler, Kişiselleştirilmiş Yaklaşım</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Sorularınız için bize mesaj bırakın</h3>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Adınız">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-postanız">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Telefonunuz">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Konunuz">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Mesajınız"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'></i>Mesaj Gönder<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Telefon Numarası veya E-posta Adresi ile Bize Ulaşın</h3>
                    <h2>
                        <a href="tel:+0905304667251">+90 (530) 466 7251</a>
                        <span>veya</span>
                        <a href="mailto:bilgi&#64;sizisoylealalim.com.tr">bilgi&#64;sizisoylealalim.com.tr</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/sizisoylealalm_/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>


    <div class="funfacts-inner">
        <div class="row">
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bx-list-check'></i>
                    <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                    <p>Tamamlanmış Projeler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-smile'></i>
                    <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                    <p>Mutlu Müşteriler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bx-grid-small'></i>
                    <h3><span class="odometer" data-count="15">00</span><span class="sign-icon">+</span></h3>
                    <p>Devam Eden Projeler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay=".8s">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bxs-award'></i>
                    <h3><span class="odometer" data-count="3">00</span><span class="sign-icon">+</span></h3>
                    <p>Kazanan Ödüller  </p>
                </div>
            </div>
        </div>
    </div>

    
</section>

