<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>HAKKIMIZDA</h2>
            <p>Sizi Şöyle Alalım | Hikayemiz</p>
           <!-- Structured Data -->
            <script type="application/ld+json" *ngIf="structuredData">
                {{ structuredData | json }}
            </script>

        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Biz Kimiz</span>
                    <!--<h2>İşletmenizi bir üst seviyeye taşıyalım</h2>-->
                    <h2>Sizi Şöyle Alalım</h2>
                    <br>
                    <p><b>Sizi Şöyle Alalım</b>, dijital dünyada markaların büyümesi ve başarılı bir şekilde rekabet edebilmesi için çözüm odaklı hizmetler sunan bir dijital pazarlama ajansıdır. SEO, dijital pazarlama ve sosyal medya yönetimi alanlarında uzman ekibimizle, müşterilerimize değer katan, yenilikçi ve stratejik çözümler geliştiriyoruz. Amacımız, markaların dijital hedeflerine ulaşmalarına yardımcı olurken aynı zamanda kalıcı bir etki yaratmaktır.</p>
                    <p><b>VİZYONUMUZ</b></p>
                    <p>Dijital dünyada sektörün öncüsü olarak, yenilikçi ve etkili stratejilerle global ölçekte tanınan bir marka olmak. Müşterilerimizin dijital dönüşümüne liderlik ederek, onların başarı hikayelerinin bir parçası olmayı hedefliyoruz.</p>
                    <p><b>MİSYONUMUZ</b></p>
                    <p>	•	İş ortaklarımızın dijital platformlarda fark yaratmalarını sağlayacak stratejiler geliştirmek.</p>
                    <p> •	Teknolojiyi ve veri odaklı analizleri kullanarak hedef kitlelere ulaşılabilirliği artırmak.</p>
                    <p> •	Yenilikçi, yaratıcı ve sürdürülebilir dijital çözümlerle müşteri memnuniyetini en üst düzeye çıkarmak.</p>
                    <p> •	Dijital pazarlama alanındaki uzmanlığımızı sürekli geliştirerek müşterilerimizin ihtiyaçlarına en iyi şekilde yanıt vermek.</p>    
                        
                        
                
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img style="border-radius: 15px;"  src="assets/img/about-img.webp" alt="seo-sosyal-medya">
                </div>
            </div>
        </div>
    </div>
</section>



<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Uzmanlarımızla Tanışın, Başarınız İçin Buradayız!</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="image">
                        <img src="assets/img/team-image/emre-ustunkurucu.webp" alt="emre-ustunkurucu-photo">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/emreustnkrucu/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Emre Üstünkurucu</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="image">
                        <img src="assets/img/team-image/feray-sarisacli.webp" alt="feray-sarisacli-photo">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Feray Sarısaçlı</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="image">
                        <img src="assets/img/team-image/arzu-terzioglu.webp" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Arzu Terzioğlu</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                    <div class="image">
                        <img src="assets/img/team-image/kenan-sarisakal.webp" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Kenan Sarısakal</h3>
                        <span>SEO Expert</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="funfacts-inner">
        <div class="row">
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bx-list-check'></i>
                    <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                    <p>Tamamlanmış Projeler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-smile'></i>
                    <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                    <p>Mutlu Müşteriler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bx-grid-small'></i>
                    <h3><span class="odometer" data-count="15">00</span><span class="sign-icon">+</span></h3>
                    <p>Devam Eden Projeler</p>
                </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay=".8s">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bxs-award'></i>
                    <h3><span class="odometer" data-count="3">00</span><span class="sign-icon">+</span></h3>
                    <p>Kazanan Ödüller  </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Sizin Büyümeniz, Bizim Önceliğimiz - Dijital Başarınızı Güçlendiriyoruz!</h2>
            <p>İşletmenizi dijital dünyada hak ettiği noktaya taşımak için SEO ve Sosyal Medya Stratejileriyle yanınızdayız. </p>
            <a routerLink="/bize-ulasin" class="default-btn"><i class="bx bxs-hot"></i>Bize Ulaşın<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>