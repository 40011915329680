import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit() {
    // Routing'den meta bilgilerini alın
    this.route.data.subscribe(data => {
      const meta = data['meta'];

      // Başlık ekle
      this.titleService.setTitle(meta.title);

      // Meta etiketlerini güncelle
      this.metaService.updateTag({ name: 'description', content: meta.description });
      this.metaService.updateTag({ name: 'robots', content: meta.robots });
      this.metaService.updateTag({ name: 'keywords', content: meta.keywords });

      // Open Graph Meta Tagleri
      this.metaService.updateTag({ property: 'og:title', content: meta.openGraph.title });
      this.metaService.updateTag({ property: 'og:description', content: meta.openGraph.description });
      this.metaService.updateTag({ property: 'og:image', content: meta.openGraph.image });
      this.metaService.updateTag({ property: 'og:url', content: meta.openGraph.url });
      this.metaService.updateTag({ property: 'og:type', content: meta.openGraph.type });

      // Twitter Card Meta Tagleri
      this.metaService.updateTag({ name: 'twitter:card', content: meta.twitter.card });
      this.metaService.updateTag({ name: 'twitter:title', content: meta.twitter.title });
      this.metaService.updateTag({ name: 'twitter:description', content: meta.twitter.description });
      this.metaService.updateTag({ name: 'twitter:image', content: meta.twitter.image });
    });
  }

}
