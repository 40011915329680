<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    
                        <a routerLink="/"><h3>SİZİ ŞÖYLE ALALIM</h3></a>
                        
                    
                    <p><b>"Sizi Şöyle Alalım”</b> ile dijital dünyada fark yaratın! <b>Sosyal Medya</b> yönetiminden <b>SEO</b> optimizasyonuna, markanızı doğru kitleyle buluşturmak için buradayız. Güvenilir, yaratıcı ve sonuç odaklı çözümlerle büyüme yolculuğunuza eşlik ediyoruz. Dijital stratejilerle hedeflerinizi gerçeğe dönüştürmek için sizinleyiz!</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Şirket</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">Hakkımızda</a></li>
                        <li><a routerLink="/">Hizmetler</a></li>
                        <li><a routerLink="/">Özellikler</a></li>
                        <li><a routerLink="/">Fiyatlandırmamız</a></li>
                        <li><a routerLink="/">Son Haberler</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Destek</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">SSS</a></li>
                        <li><a routerLink="/">Gizlilik Politikası</a></li>
                        <li><a routerLink="/">Şartlar ve Koşullar</a></li>
                        <li><a routerLink="/">Toplum</a></li>
                        <li><a routerLink="/">Bize Ulaşın</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>İletişim Bilgileri</h3>
                    <ul class="footer-contact-info">
                        <li>Adres: <a href="https://maps.app.goo.gl/SAiGnejymHsxfNQF6" target="_blank">Karasoku Mahallesi 28028 Sk. No:5/205 ADANA</a></li>
                        <li>E-Posta: <a href="mailto:bilgi&#64;sizisoylealalim.com.tr">bilgi&#64;sizisoylealalim.com.tr</a></li>
                        <li>Telefon: <a href="tel:+0905304667251">+90 (530) 466 7251</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/sizisoylealalm_/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Sizi Şöyle Alalım <a href="https://sizisoylealalim.com.tr/" target="_blank">Sizi Şöyle Alalım</a></p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>